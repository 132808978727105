<template>
    <div class="elem">
    <PreLoader v-if="isLoading"></PreLoader>
        <div class="site-wrap" id="home-section">
            <topHeader/>
            <v-card
                class="elem__cards"
            >
                <v-card-title>
                    <v-row>
                        <v-col md="6" cols="6">
                            <h5><b>Elementary Grammar</b></h5>
                        </v-col>
                        <v-col md="6" cols="6" align="end">
                            <v-btn style="color:#fff;" color="#f0bd30" @click="goTo('elementary')">Артка кайтуу</v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-container fluid>
                    <v-row dense>
                        <v-col
                        v-for="item in items"
                        :key="item.id"
                        md="4" sm="6" xs="12" cols="12"
                        >
                            <v-card @click="toTest(item)">
                                <!-- <v-img
                                :src="card.src"
                                class="white--text align-end"
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                
                                > </v-img>-->
                                
                                <v-card-title><h5>{{item.title}}</h5></v-card-title>
                                

                                <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn icon v-if="item.isFav">
                                    <v-icon color="red" @click="setFav(card)">mdi-heart</v-icon>
                                </v-btn>
                                <v-btn icon v-if="!item.isFav">
                                    <v-icon color="red" @click="setFav(card)">mdi-heart-outline</v-icon>
                                </v-btn>

                                <v-btn icon>
                                    <v-icon color="primary">mdi-bookmark</v-icon>
                                </v-btn>

                                <v-btn icon>
                                    <v-icon color="#ff8b00" @click="sharedialog= true">mdi-share-variant</v-icon>
                                </v-btn>
                                
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
            
            <v-dialog
                v-model="sharedialog"
                width="500"
                
                >
                <v-card>
                    <v-card-title class="headline grey lighten-2">
                    Privacy Policy
                    </v-card-title>

                    <v-card-text style="font-size:18px;" class="mt-5">
                        <ShareNetwork
                            v-for="network in networks"
                            :network="network.network"
                            :key="network.network"
                            :style="{backgroundColor: network.color,marginLeft: network.mar,borderRadius:network.pad,padding:network.pad}"
                            :url="sharing.url"
                            :title="sharing.title"
                            :description="sharing.description"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                            :twitterUser="sharing.twitterUser"
                        >
                            
                            <i style="margin: 10px 0px 10px 0px;" :class="network.icon"> {{ network.name }}</i>
                        </ShareNetwork>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="sharedialog = false"
                        >
                            I accept
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <Footer/>
        </div>
    </div>
</template>
<script>
import topHeader from '../../views/navigation/topHeader';
import Footer from '../../views/navigation/Footer';
import PreLoader from '../../components/preloader/PreLoader';
import * as firebase from '@/firebase'
export default {
    components: {
      topHeader,
      Footer,
      PreLoader
    },
    data: () => ({
        sharedialog: false,
        isFav: false,
        isLoading:false,
        cards: [
            {   
                id:1,
                title:'Auxiliary Verb TO BE',
                textTitle: 'Aibek’s Family', 
                text: 'My name is Aibek. I am seventeen years old. My home is in Osh city, in Kyrgyzstan. But I am not from Kyrgyzstan – I am from Kazakhstan. I live with my parents. My father’s name is Nurbek, and my mother’s name is Ainura. My father is a policeman, my mother is an English teacher at school. I have two sisters. Their names are Aida and Aidana. They live in Shymkent, Kazakhstan. I am a school student, but my sisters’ study at university. We have two houses in two countries. We go to Kazakhstan very often. We love both Kyrgyzstan and Kazakhstan. ',
                quesOne: 'Are the following statements true or false? Read the text first.',
                questOneVariants: [
                    {title: '1', text:'Aibek lives in Kazakhstan.', isTrue: false,},
                    {title: '2', text:'He has two sisters', isTrue: true,},
                    {title: '3', text:'His father is an English teacher.', isTrue: false,},
                    {title: '4', text:'His mother is a housewife.', isTrue: false,},
                    {title: '5', text:'His sisters are school students.', isTrue: false,},
                    {title: '6', text:'His sisters live in Shymkent, in Kazakhstan.', isTrue: true,}
                ],
                isFav: false,
                quesTwo: 'Fill the gaps with the correct auxiliary verb TO BE',
                questtwoVariants: [
                    {title: '1', text:'My name ___ Aibek', answer: 'is',},
                    {title: '2', text:'He ___ a policeman.', answer: 'is',},
                    {title: '3', text:'They ___ university students', answer: 'are',},
                    {title: '4', text:'I ___ from Kazakhstan.', answer: 'am',},
                    {title: '5', text:'We ___ Kazakh. ', answer: 'are',}
                ],
            },
            {   
                id:2,
                title:'Indefinite Article A/An and Plurals',
                textTitle: 'Marat’s day', 
                text: 'Marat is 9 years old. He is in 3rd grade. He goes to school every day. His school bag is very big. He takes to school, books, a pen, pencils, an eraser, copybooks, an apple, a lunch box, and a smartphone. He has lovely friends and kind teachers. He loves studying and playing with his friends. At school, they have many colorful classrooms, a sports bubble, a cafeteria, a swimming pool, football, and basketball courts. After the classes, Marat goes home. At home, he has a cat and a dog. Everyday Marat feeds them, goes for a walk, and washes his pets. Marat is a good boy. ',
                quesOne: 'Fill the gaps with correct Indefinite Article A/An and Plurals',
                questOneVariants: [
                    {title: '1', text:'Marat has ___ dog and ___ cat.', answer: 'a',},
                    {title: '2', text:'They have ___ swimming pool at school.', answer: 'a',},
                    {title: '3', text:'He takes to school ___ eraser.', answer: 'an',},
                    {title: '4', text:'Marat is ___ good boy.', answer: 'a',},
                    {title: '5', text:'He has ___ kind teachers.', answer: '',}
                ],
                isFav: false,
                quesTwo: 'Look if the sentences are True or False. Correct the mistakes.',
                questtwoVariants: [
                    {title: '1', text:'Marat does not take an dog and an cat to school', answer: 'Marat does not take a dog and a cat to school.',},
                    {title: '2', text:'Marat has a kind teachers.', answer: 'Marat has kind teachers.',},
                    {title: '3', text:'Marat has lovely friends.', answer: 'true',},
                    {title: '4', text:'Marat has an smartphone.', answer: 'Marat has a smartphone.',},
                    {title: '5', text:'He uses a eraser at school.', answer: 'He uses an eraser at school.',}
                ],
            },
            {   
                id:3,
                title:'Possessive Adjectives',
                textTitle: 'Samara at Maksat’s house', 
                text: 
                    'Samara: Is that your cat' +'?'+'<br>'+
                    'Maksat: No. I have not got a cat. I have got a dog – his name is Bob. And my sister has a rabbit.'+'<br>'+
                    'Samara: What is her name?'+'<br>'+
                    'Maksat: Floppy.'+'<br>'+
                    'Samara: Not the rabbit – your sister!'+'<br>'+
                    'Maksat: Oh, sorry. Her name is Elina.'+'<br>'+
                    'Samara: Have you got a brother? '+'<br>'+
                    'Maksat: Yes, I have. His name is Samat. But he is not at home.'+'<br>'+
                    'Samara: Where is he?'+'<br>'+
                    'Maksat: I am not sure. He has not got his mobile with him.'+'<br>'+
                    'Samara: Are these books yours? '+'<br>'+
                    'Maksat: No, these books are my brothers. He loves reading books.'+'<br>'+
                    'Samara: oh, I like his books. Can I borrow?'+'<br>'+
                    'Maksat: Of course, but you better ask it from my brother. It is not mine.'+'<br>'+
                    'Samara: Okay, I will wait for him.',
                quesOne: 'Look if the sentences are True or False.',
                questOneVariants: [
                    {title: '1', text:'Samat does not like reading books.', answer: false,},
                    {title: '2', text:'Samara does not like Samat’s book.', answer: false,},
                    {title: '3', text:'Maksat has a dog, and his name is Bob.', answer: true,},
                    {title: '4', text:'Elina has a rabbit. ', answer: true,},
                    {title: '5', text:'Maksat’s brother took his phone with him.', answer: false,}
                ],
                isFav: false,
            },
            {   
                id:4,
                title:'Possessive'+`'`+ 's Whose?...',
                textTitle: 'Samara visits Isa',
                text: 
                    'Samara: Whose is this room? It is very beautiful.'+'<br>' +
                    'Isa: It is my and my brother’s room.'+'<br>'+
                    'Samara: Do you play guitar? Whose is this?'+'<br>'+
                    'Isa: No, I do not play guitar. It is my brother’s; he is a singer.'+'<br>'+
                    'Samara: Wow, that is great. And whose are these sport dumbbells?'+'<br>'+
                    'Isa: Oh, these are mine. I am a UFC fighter.'+'<br>'+
                    'Samara: I like this painting. Whose is this?'+'<br>'+
                    'Isa: It is mine, but it is not finished. I like to paint in my free time.'+'<br>'+
                    'Samara: You are a true talent.', 
                quesOne: 'Look if the sentences are True or False.',
                questOneVariants: [
                    {title: '1', text:'Isa is a singer, and he can play guitar.', answer: false,},
                    {title: '2', text:'Isa does not have a brother; he has two sisters.', answer: false,},
                    {title: '3', text:'Isa is a UCF fighter.', answer: true,},
                    {title: '4', text:'Isa plays football in his free time.', answer: false,},
                    {title: '5', text:'Isa lives alone in his room.', answer: false,}
                ],
                isFav: false,
            },
           
            
        ],
        sharing: {
            url: 'https://zhyldyzacademy.kg',
            title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
            description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
            quote: 'The hot reload is so fast it\'s near instant. - Evan You',
            hashtags: 'vuejs,vite,javascript',
            twitterUser: 'youyuxi'
        },
        networks: [
            { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#EA4335', mar:'10px',pad:'6px' },
            { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2',mar:'10px',pad:'6px' },
            { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5',mar:'10px',pad:'6px'},
            { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff',mar:'10px',pad:'6px' },
            { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b',mar:'10px',pad:'6px' },
            { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc',mar:'10px',pad:'6px' },
            { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2',mar:'10px',pad:'6px' },
            { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8',mar:'10px',pad:'6px' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366',mar:'10px',pad:'6px' },
        ],
        playerVars: {
            autoplay: 0
        },
        items:[]
    }),
    mounted() {},
    created() {
        this.getTests();
    },
    methods: {
        async getTests() {
            this.isLoading = true;
             this.events = await firebase.eGrammarCollection.get()
                .then((snapshot) => {
                    snapshot.forEach((el) => {
                        if(el.data().id){
                            this.items.push({...el.data()})
                        }
                    });
			})
            console.log(this.items);
            this.isLoading = false;
        },
        playing() {
            console.log('we are watching!!!')
        },
        setFav(item) {
            item.isFav = true;
        },
        toTest(item) {
            //console.log(item);
            this.$router.push({ name: 'AdElemGrammarTest', params: { testId: item.id }});
            
        }
    }
}
</script>
<style lang="scss" scoped>
.elem{
    &__cards {
        
    }
}
.v-application a {
    color: #fff  !important;
    & span {
        margin:10px;
    }
}
</style>